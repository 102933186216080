body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.flex{display: flex;}
.flexCenter{display: flex;align-items: center;}
.flexAllCenter{display: flex;align-items: center;justify-content: center;}
.rowFlex{flex: 1;}
.padd10{padding: 2vw;}
.padd15{padding: 4vw;}
.paddl15{padding-left: 4vw;}
.paddr15{padding-right: 4vw;}
.paddt15{padding-top: 4vw;}
.paddb15{padding-bottom: 4vw;}
.borderb1ef{border-bottom: 1px solid #EFEFEF;}


.paybox{padding-bottom: 150px;}
.user{font-size: 14px;color: #333;text-align: center;padding-top: 40px;}
.mbox{width: 54vw;margin: 20px auto 30px;border-bottom: 1px solid #ddd;padding-bottom: 6px;}
.mbox h1{font-size: 30px;color: #333;}
.mbox input{font-size: 24px;padding-left: 10px;}
.remarkbox{width: 92vw;border-radius: 10px;background-color: #F7F7F7;margin: 0 auto 15px;}
.remarkbox p{width: 50px;font-size: 16px;}
.remarkbox input{text-align: right;font-size: 16px;}
.typebox{margin: 0 4vw;padding: 4.4vw 0;}
.typebox .icon{display: block;width: 30px;margin-right: 10px;}
.typebox p{font-size: 16px;}
.typebox .quan{display: block;width: 18px;height: 18px;border-radius: 100%;border: 1px solid #333;position: relative;}
.typebox .quan.active{border-color: #F3762D;}
.typebox .quan.active::before{display: block;content: '';width: 10px;height: 10px;border-radius: 100%;background-color: #F3762D;position: absolute;left: 4px;top: 4px;}
.fixed{position: fixed;left: 0;right: 0;bottom: 0;background-color: #fff;z-index: 999;padding-bottom: 4vw;}
.tipbox{padding: 10px;border-top: 1px solid #F4F4F4;border-bottom: 1px solid #F4F4F4;}
.tipbox .kk{width: 12px;height: 12px;border: 1px solid #878787;margin-right: 4px;}
.tipbox .kk.active{background-color: #F3762D;border-color: #F3762D;}
.tipbox .kk .gou{display: block;width: 8px;margin-top: 1px;}
.tipbox p{font-size: 14px;color: #878787;}
.btn{width: 92vw;margin: 4vw auto 0;border-radius: 5px;background-color: #11C292;text-align: center;line-height: 44px;font-size: 18px;color: #fff;}
.langBtn{
  position: absolute;
  width: 100px;
  height: 30px;
  top:5px;
  right: 0px;
  display: flex;
  flex-direction: row;
  border:1px solid #11C292;
  border-radius: 5px;
}
.langItem{
  flex:1;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
}
.langActive{
  color: #fff;
  background-color: #11C292;
}

